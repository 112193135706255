import { IconLookup } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { backgroundDark, success, info } from '@constants/colors';

type ComponentProps = {
  background?: 'backgroundDark' | 'success' | 'info';
};

const Component = styled.div<ComponentProps>`
  padding: 8px 12px;
  color: #ffffff;
  background: ${({ background }) => {
    switch (background) {
      case 'success':
        return success;
      case 'info':
        return info;
      default:
        return backgroundDark;
    }
  }};
  border-radius: 8px;
  max-width: 500px;
  & > svg {
    margin-left: 8px;
  }
`;

type Props = {
  text: string;
  icon?: IconLookup;
  background?: 'backgroundDark' | 'success' | 'info';
};

const Notification: React.FC<Props> = ({
  text,
  icon,
  background = 'backgroundDark',
}) => {
  return (
    <Component background={background}>
      {text}
      {icon && <FontAwesomeIcon icon={icon} />}
    </Component>
  );
};

export default Notification;
