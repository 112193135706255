import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

import { fontMedium, lineLight, brand } from '@constants/colors';
import { space } from '@constants/spaces';

const Component = styled.label`
  cursor: pointer;
`;

const Label = styled.label`
  display: block;
  font-size: 12px;
  line-height: normal;
  color: ${fontMedium};
  margin-bottom: ${space}px;
  user-select: none;
`;

const Holder = styled.div<{ disabled: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  ${(props) => props.disabled && 'opacity: 0.5;'}
`;

const Input = styled.div`
  position: absolute;
  opacity: 0;
`;

const Icon = styled.div<{ checked: boolean }>`
  width: 20px;
  height: 20px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 2px;
  border: 2px solid ${(props) => (props.checked ? brand : lineLight)};
  background: ${(props) => (props.checked ? brand : '#fff')};
  margin-right: ${space * 2}px;
  transition: border 0.2s ease, background 0.2s ease;

  svg {
    transition: transform 0.25s ease 0.1s;
    ${(props) =>
      props.checked ? 'transform: scale(1);' : 'transform: scale(0);'}
  }
`;

type Props = {
  name?: string;
  label?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  checked: boolean;
  onChange?: () => void;
  onBlur?: () => void;
};

const Checkbox = ({
  name,
  label,
  disabled = false,
  onChange,
  onBlur,
  checked,
  children,
}: Props) => {
  return (
    <Component>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Holder disabled={disabled}>
        <Icon checked={checked}>
          <FontAwesomeIcon icon={faCheck} />
        </Icon>
        <Input>
          <input
            type="checkbox"
            disabled={disabled}
            onBlur={onBlur} // notify when input is touched
            onChange={onChange} // send value to hook form
            checked={checked}
          />
        </Input>
        {children}
      </Holder>
    </Component>
  );
};

export default Checkbox;
