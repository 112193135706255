import breakpoints from './breakpoints';

const customMediaQuery = (width: number, isMinWidth?: boolean): string => {
  if (isMinWidth) return `@media (min-width: ${width}px)`;
  return `@media (max-width: ${width}px)`;
};

const custom = customMediaQuery;
const smallTablet = customMediaQuery(breakpoints.smallTablet, true); // 544
const tablet = customMediaQuery(breakpoints.tablet, true); // 768
const laptop = customMediaQuery(breakpoints.laptop, true); // 992
const desktop = customMediaQuery(breakpoints.desktop, true); // 1200
const maxWidth = {
  mobile: customMediaQuery(breakpoints.tablet - 1), // 767
  tablet: customMediaQuery(breakpoints.laptop - 1), // 991
  laptop: customMediaQuery(breakpoints.desktop - 1), // 1199
};

export { custom, smallTablet, tablet, laptop, desktop, maxWidth };
