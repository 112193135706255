import { createGlobalStyle } from 'styled-components';

import { fontDark } from '@constants/colors';

// h1 = h700
// h2 =
// h3 = h600
// h4 = h500
// h5 = h400

export default createGlobalStyle`
  h1 {
    font-size: 24px;
    font-weight: 500;
    color: ${fontDark};
    margin: 0;
  }

  h3 {
    font-size: 20px;
    font-weight: 500;
    color: ${fontDark};
    margin: 0;
  }

  h4 {
    font-size: 16px;
    font-weight: 500;
    color: ${fontDark};
    margin: 0;
  }

  h5 {
    font-size: 14px;
    font-weight: 500;
    color: ${fontDark};
    margin: 0;
  }
`;
