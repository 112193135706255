import { exampleBooks, faq, ourStory, prices } from '@constants/routes';

export const navigation = [
  {
    label: 'Voorbeelden',
    href: exampleBooks,
  },
  {
    label: 'Prijzen & levertijd',
    href: prices,
  },
  {
    label: 'Ons verhaal',
    href: ourStory,
  },
  {
    label: 'Klantenservice',
    href: faq,
    external: true,
  },
];
