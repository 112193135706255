import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import DropdownWrapper from '@components/DropdownWrapper';
import { black900, grey600 } from '@constants/colors';
import { textWidth } from '@constants/sizes';
import { space } from '@constants/spaces';

const Component = styled.div`
  margin: 0 ${space}px;
  position: relative;
  display: inline-block;
  font-size: 16px;
  vertical-align: text-top;
`;

const Description = styled.div`
  padding: ${space * 2}px ${space * 3}px;
  color: ${black900};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  max-width: ${textWidth};
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${grey600};
`;

type Props = {
  children: React.ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
  align?: 'left' | 'right' | 'center' | 'top' | 'bottom';
};

const Info = ({ children, position = 'top', align = 'right' }: Props) => {
  const child = <Description>{children}</Description>;
  return (
    <Component>
      <DropdownWrapper
        dropdownChildren={child}
        position={position}
        align={align}
        showMode="onHover"
      >
        <Icon icon={faCircleQuestion} />
      </DropdownWrapper>
    </Component>
  );
};

export default Info;
