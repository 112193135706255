import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

type Props = {
  children: React.ReactElement;
};

const InView = ({ children }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setInView(true);
      }
    });

    if (ref.current) observer.observe(ref.current);

    return () => observer.disconnect();
  }, [ref]);

  return <Component ref={ref}>{inView ? children : null}</Component>;
};

export default InView;
