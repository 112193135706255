import { ApolloError, useApolloClient } from '@apollo/client';
import cloneDeep from 'lodash/cloneDeep';
import { useRouter } from 'next/router';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useSpreadStore } from 'store/spreadStore';
import useAsyncQueue from 'use-async-queue';

import {
  book as bookPath,
  templateCollaborativePages,
} from '@constants/routes';
import { refetchQueries } from '@graphql/Apollo';
import {
  Book,
  BookSpread,
  GetBookByIdDocument,
  GetBookMembersDocument,
  GetBookMembersQuery,
  GetCustomImagesDocument,
  GetCustomImagesQuery,
  GetSpineDocument,
  GetSpreadByIdDocument,
  MemberRole,
  MemberStatus,
  Role,
  useBookJoinMutation,
  useBookMarkAsCompleteMutation,
  useGetAccountInfoQuery,
  useGetBookByIdQuery,
  useGetBookMembersQuery,
  useGetCustomImagesQuery,
  useGetMediaQuery,
  useOnElementAddSubscription,
  useOnElementRemoveSubscription,
  useOnElementReorderSubscription,
  useOnElementUndoSubscription,
  useOnElementUpdateSubscription,
  useOnSpineUpdateSubscription,
  useOnSpreadAddSubscription,
  useOnSpreadBackgroundUpdateSubscription,
  useOnSpreadRemoveSubscription,
  useOnSpreadReorderSubscription,
  useUndoSpreadMutation,
} from '@graphql/generated/graphql';
import { GetMediaQuery } from '@graphql/generated/page';
import useMediaQuery from '@hooks/useMediaQuery';

import { SpreadSide } from '../types/spread-side';

type ContextProps = {
  book: Book;
  bookLoading: boolean;
  bookError: ApolloError;
  media: GetMediaQuery;
  customImages: GetCustomImagesQuery;
  bookMembers: GetBookMembersQuery;
  currentSpread: BookSpread;
  bookHasCollaborativePages: boolean;
  bookHasInvitedUsers: boolean;
  onUndo: () => void;
  bookMarkComplete: () => void;
  isLoggedIn: boolean;
  isAdmin: boolean;
  isOwner: boolean;
  participantStatus: MemberStatus;
  participantIsFinished: boolean;
  firstTemplatePageId: string;
  isBookTemplate: boolean;
  isUserCoOwner: boolean;
  bookMembersLoading: boolean;
  userPage: BookSpread;
  isParticipant: boolean;
  bookIdMissing: boolean;
  refetchBook: () => void;
  refetchCustomImages: () => void;
};

const BookContext = createContext({} as ContextProps);

type Props = {
  children: React.ReactNode;
};

export const BookProvider = ({ children }: Props) => {
  // TODO: temporary solution to keep track of active spread
  // in the future, we should use the spread store for this
  // and hopefully not use this here at all
  const apolloClient = useApolloClient();
  const activeSpread = useSpreadStore((s) => s.activeSpread);
  const setActiveSpread = useSpreadStore((s) => s.setActiveSpread);
  const fetchActiveSpread = useSpreadStore((s) => s.fetch);
  const setActiveSpreadSide = useSpreadStore((s) => s.setActiveSpreadSide);

  const [book, setBook] = useState<Book>();
  const router = useRouter();
  const {
    query: { bookId, spreadId, invite, page },
  } = router;
  const { isMobile } = useMediaQuery();

  const sanitizedInvitationToken = invite
    ?.toString()
    .replace(/ .*|%.*|\?.*/, '');
  const {
    data,
    loading,
    error,
    refetch: refetchBook,
  } = useGetBookByIdQuery({
    variables: {
      bookId,
      invitationToken: sanitizedInvitationToken,
    },
    skip: !bookId,
    onCompleted: (data) => {
      setBook(data.book as Book);
    },
  });

  // resetting the book state after users 'create' a book, otherwise template book spreads are preserved?
  useEffect(() => {
    if (data?.book.id === bookId) return;
    setBook(data?.book as Book);
  }, [setBook, data?.book, bookId]);

  useEffect(() => {
    if (
      isMobile &&
      page &&
      Object.values(SpreadSide).includes(page as SpreadSide)
    ) {
      setActiveSpreadSide(page as keyof typeof SpreadSide | null); // Cast 'page' to the correct type
    }
  }, [isMobile, page, setActiveSpreadSide]);

  const bookIdMissing =
    error?.message === 'Response not successful: Received status code 422';

  // BOOK DATA
  const { data: media } = useGetMediaQuery({
    skip: !bookId,
  });
  const { data: customImages, refetch: refetchCustomImages } =
    useGetCustomImagesQuery({
      variables: { bookId },
      skip: !bookId,
    });
  const { data: bookMembers, loading: bookMembersLoading } =
    useGetBookMembersQuery({
      variables: { bookId },
      skip: !bookId,
    });
  const currentSpread = data?.book.spreads.find(
    (spread) => spread.id === spreadId,
  );

  activeSpread?.id !== spreadId &&
    fetchActiveSpread(spreadId as string, apolloClient);

  const bookHasCollaborativePages =
    data?.book && !!data.book.spreads.find((spread) => spread.isCollaborative);
  const bookHasInvitedUsers =
    (data?.book &&
      data?.book.spreads.find(
        (spread) => spread.isCollaborative && !spread.isTemplate,
      )) ||
    (bookMembers?.bookMembers.length && bookMembers?.bookMembers.length > 1);
  const firstTemplatePageId = data?.book.spreads.find(
    (spread) => spread.isTemplate,
  )?.id;

  // USER DATA
  const { data: account } = useGetAccountInfoQuery();
  const isLoggedIn = account?.account.id;
  const isAdmin = account?.account.role === Role.Admin;
  const isOwner =
    data?.book.owner &&
    account?.account &&
    (data?.book.owner?.id === account?.account.id ||
      data.book.memberRole === MemberRole.CoOwner);
  const isParticipant =
    isOwner ||
    data?.book.memberRole === MemberRole.CoOwner ||
    (account?.account &&
      data?.book?.spreads.find(
        (spread) => spread.author?.email === account?.account.email,
      ));
  // TODO: either look for participant's status here or Sjeng might add a new memberStatus property on the book
  const participantStatus = isLoggedIn && !isOwner && data?.book.memberStatus;
  const participantIsFinished =
    participantStatus && participantStatus === MemberStatus.Complete;
  const memberRole = data?.book.memberRole;
  const isUserCoOwner = memberRole === MemberRole.CoOwner;
  const userPage =
    isLoggedIn &&
    bookHasCollaborativePages &&
    data?.book.spreads.find(
      (spread) =>
        spread.isCollaborative &&
        !spread.isTemplate &&
        spread.author?.email === account?.account.email,
    );

  // BOOK ACTIONS
  const [bookSpreadUndo] = useUndoSpreadMutation();
  const [bookMarkAsComplete] = useBookMarkAsCompleteMutation();
  const [bookJoin, { called }] = useBookJoinMutation();

  // TODO: missing data here, returns boolean
  const onUndo = () => {
    bookSpreadUndo({
      variables: { spreadId: currentSpread?.id },
      refetchQueries: [
        {
          query: GetCustomImagesDocument,
          variables: { bookId: data?.book.id },
        },
      ],
    });
  };

  const bookMarkComplete = () => {
    bookMarkAsComplete({
      variables: {
        bookID: bookId as string,
      },
      refetchQueries: refetchQueries(bookId?.toString()),
    });
  };

  const value = {
    book: book || data?.book,
    bookLoading: loading,
    bookError: error,
    media,
    customImages,
    bookMembers,
    currentSpread,
    bookHasCollaborativePages,
    bookHasInvitedUsers,
    onUndo,
    bookMarkComplete,
    isLoggedIn,
    isAdmin,
    isOwner,
    participantStatus,
    participantIsFinished,
    firstTemplatePageId,
    isBookTemplate: data?.book.isTemplate,
    isUserCoOwner,
    bookMembersLoading,
    userPage,
    isParticipant,
    bookIdMissing,
    refetchBook,
    refetchCustomImages,
  };

  const navigateToExistingSpread = useCallback(() => {
    const isOnTemplatePage =
      router.asPath.split('?')[0] ===
      templateCollaborativePages(bookId as string, spreadId as string);

    const spreadsIDs = book?.spreads
      ?.filter((spread) =>
        isOnTemplatePage ? spread?.isTemplate : !spread?.isTemplate,
      )
      .map((spread) => spread?.id);
    const navToPage =
      spreadsIDs &&
      (spreadsIDs[spreadsIDs.indexOf(spreadId) - 1] ||
        spreadsIDs[spreadsIDs.indexOf(spreadId) + 1] ||
        spreadsIDs[0]);

    router.push(
      isOnTemplatePage
        ? templateCollaborativePages(bookId as string, navToPage)
        : bookPath(bookId as string, navToPage),
    );
  }, [book, router, spreadId, bookId]);

  useEffect(() => {
    // user join book if there is an invite param (only once)
    if (
      isLoggedIn &&
      !isOwner &&
      sanitizedInvitationToken &&
      data?.book &&
      !userPage &&
      !called
    ) {
      bookJoin({
        variables: {
          invitationToken: sanitizedInvitationToken,
        },
        onCompleted: (data) => {
          if (bookHasCollaborativePages) {
            const userSpread = data?.bookJoin?.spreads?.find((spread) => {
              return spread.author?.email === account?.account?.email;
            });
            // re-direct user to their page
            router.push(bookPath(bookId as string, userSpread?.id));
            router.reload();
          } else {
            // re-direct co-owner to first page
            router.push(bookPath(bookId as string, ''));
            router.reload();
          }
        },
        refetchQueries: bookHasCollaborativePages
          ? undefined
          : [GetBookByIdDocument, GetBookMembersDocument],
      });
    }

    // redirect user to their own page if they've already joined
    if (
      isLoggedIn &&
      !isOwner &&
      sanitizedInvitationToken &&
      data?.book &&
      userPage
    ) {
      router.push(bookPath(bookId as string, userPage?.id));
    }
  }, [
    isLoggedIn,
    sanitizedInvitationToken,
    bookJoin,
    account?.account.email,
    router,
    bookId,
    data?.book,
    isOwner,
    userPage,
    called,
    bookHasCollaborativePages,
  ]);

  // <-- WEBSOCKETS -->

  const [latestTask, setLatestTask] = useState<any>(null);

  // Websockets queue
  const queue = useAsyncQueue({
    concurrency: 1,
    inflight: (task) => {
      setLatestTask(task.id);
    },
    done: (task) => {},
    drain: () => {},
  });

  const fetchSpread = (spreadId: string) => {
    return apolloClient.query({
      query: GetSpreadByIdDocument,
      variables: { spreadId },
    });
  };

  // TODO: Doesn't work well when users join the book
  useOnSpreadAddSubscription({
    skip: !bookId,
    shouldResubscribe: true,
    variables: { bookID: bookId },
    onSubscriptionData: async ({ subscriptionData }) => {
      const spreadAdd = subscriptionData.data?.spreadAdd;

      if (book?.spreads && spreadAdd?.id) {
        queue.add({
          id: `spreadAdd ${spreadAdd?.id}`,
          task: async () => {
            await refetchBook();
          },
        });
      }
    },
  });

  useOnSpreadReorderSubscription({
    skip: !bookId,
    shouldResubscribe: true,
    variables: { bookID: bookId },
    onSubscriptionData: ({ subscriptionData }) => {
      const spreadReorder = subscriptionData.data?.spreadReorder;

      if (
        book?.spreads &&
        spreadReorder?.destinationIndex &&
        spreadReorder?.sourceIndex
      ) {
        queue.add({
          id: `spreadReorder ${spreadReorder?.sourceIndex} ${spreadReorder?.destinationIndex} ${spreadReorder?.spreadID}`,
          task: async () => {
            await refetchBook();
          },
        });
      }
    },
  });

  useOnSpreadRemoveSubscription({
    skip: !bookId,
    shouldResubscribe: true,
    variables: { bookID: bookId },
    onSubscriptionData: ({ subscriptionData }) => {
      const spreadId = subscriptionData.data?.spreadRemove.id;

      if (book?.spreads && spreadId) {
        queue.add({
          id: `spreadRemove ${spreadId}`,
          task: async () => {
            await refetchBook();
            navigateToExistingSpread();
          },
        });
      }
    },
  });

  useOnElementAddSubscription({
    skip: !bookId,
    shouldResubscribe: true,
    variables: { bookID: bookId },
    onSubscriptionData: async ({ subscriptionData }) => {
      const addElement = subscriptionData.data?.addElement;

      if (book?.spreads && addElement?.spreadID) {
        queue.add({
          id: `elementAdd ${addElement?.id}`,
          task: async () => {
            await refetchBook();
            await refetchCustomImages();
          },
        });
      }
    },
  });

  useOnElementReorderSubscription({
    skip: !bookId,
    shouldResubscribe: true,
    variables: { bookID: bookId },
    onSubscriptionData: async ({ subscriptionData }) => {
      const reorderElement = subscriptionData.data?.reorderElement;

      if (book?.spreads && reorderElement?.spreadID) {
        queue.add({
          id: `elementReorder ${reorderElement?.sourceIndex} ${reorderElement?.destinationIndex}`,
          task: async () => {
            await refetchBook();
          },
        });
      }
    },
  });

  useOnElementUpdateSubscription({
    skip: !bookId,
    shouldResubscribe: true,
    variables: { bookID: bookId },
    onSubscriptionData: async ({ subscriptionData }) => {
      const updateElement = subscriptionData.data?.updateElement;
      if (book?.spreads && updateElement?.spreadID) {
        queue.add({
          id: `elementUpdate ${updateElement?.id}`,
          task: async () => {
            const {
              data: { spread },
            } = await fetchSpread(updateElement?.spreadID);

            setActiveSpread(spread as BookSpread);

            const newBook = cloneDeep(book);
            const spreadIndex = newBook.spreads.findIndex(
              (s) => s.id === spread.id,
            );
            if (spreadIndex < 0) return;
            newBook.spreads.splice(spreadIndex, 1, spread);
            setBook(newBook as Book);
            await refetchCustomImages();
          },
        });
      }
    },
  });

  // TODO: sometimes joiners can't delete elements right after joining the book
  useOnElementRemoveSubscription({
    skip: !bookId,
    shouldResubscribe: true,
    variables: { bookID: bookId },
    onSubscriptionData: async ({ subscriptionData }) => {
      const removeElement = subscriptionData.data?.removeElement;
      if (book?.spreads && removeElement?.id && removeElement?.spreadID) {
        queue.add({
          id: `elementRemove ${removeElement.id}`,
          task: async () => {
            await refetchCustomImages();
            await refetchBook();
          },
        });
      }
    },
  });

  useOnElementUndoSubscription({
    skip: !bookId,
    shouldResubscribe: true,
    variables: { bookID: bookId },
    onSubscriptionData: async ({ subscriptionData }) => {
      const undoElement = subscriptionData.data?.undoElement;

      if (book?.spreads && undoElement?.spreadID) {
        queue.add({
          id: `elementUndo ${undoElement?.id}`,
          task: async () => {
            const {
              data: { spread },
            } = await fetchSpread(undoElement?.spreadID);

            setActiveSpread(spread as BookSpread);

            const newBook = cloneDeep(book);
            const spreadIndex = newBook.spreads.findIndex(
              (s) => s.id === spread.id,
            );

            if (spreadIndex < 0) return;
            newBook.spreads.splice(spreadIndex, 1, spread);
            setBook(newBook as Book);
          },
        });
      }
    },
  });

  useOnSpineUpdateSubscription({
    skip: !bookId,
    shouldResubscribe: true,
    variables: { bookID: bookId },
    onSubscriptionData: async ({ subscriptionData }) => {
      const spineUpdate = subscriptionData.data?.spineUpdate;

      if (book?.spreads && spineUpdate?.bookID) {
        queue.add({
          id: `spineUpdate ${spineUpdate?.bookID}`,
          task: async () => {
            const {
              data: {
                book: { spine },
              },
            } = await apolloClient.query({
              query: GetSpineDocument,
              variables: { bookId: spineUpdate?.bookID },
              fetchPolicy: 'network-only',
            });
            const newBook = cloneDeep(book);
            newBook.spine = { ...newBook.spine, ...spine };
            setBook(newBook as Book);
          },
        });
      }
    },
  });

  useOnSpreadBackgroundUpdateSubscription({
    skip: !bookId,
    shouldResubscribe: true,
    variables: { bookID: bookId },
    onSubscriptionData: async ({ subscriptionData }) => {
      const spreadBackgroundUpdate =
        subscriptionData.data?.spreadBackgroundUpdate;

      if (book?.spreads && spreadBackgroundUpdate?.spreadID) {
        queue.add({
          id: `spreadBackgroundUpdate ${spreadBackgroundUpdate?.spreadID}`,
          task: async () => {
            const {
              data: { spread },
            } = await fetchSpread(spreadBackgroundUpdate?.spreadID);

            setActiveSpread(spread as BookSpread);

            const newBook = cloneDeep(book);
            const spreadIndex = newBook.spreads.findIndex(
              (s) => s.id === spread.id,
            );
            if (spreadIndex < 0) return;
            newBook.spreads.splice(spreadIndex, 1, spread);
            setBook(newBook as Book);
          },
        });
      }
    },
  });

  // <-- WEBSOCKETS -->

  return (
    <BookContext.Provider value={value as ContextProps}>
      {children}
    </BookContext.Provider>
  );
};

export default BookContext;
