/* Responsive utils */
const upToSmallTablet = 543; // Small devices (landscape phones)
const smallTablet = 544; // Small devices (landscape phones)
const upToTablet = 767; // Medium devices (tablets)
const tablet = 768; // Medium devices (tablets)
const upToLaptop = 991; // Large devices (desktops)
const laptop = 992; // Large devices (desktops)
const upToDesktop = 1199; // Extra large devices (large desktops)
const desktop = 1200; // Extra large devices (large desktops)

const breakpoints = {
  desktop,
  upToDesktop,
  laptop,
  upToLaptop,
  tablet,
  upToTablet,
  smallTablet,
  upToSmallTablet,
};

export default breakpoints;
