import styled from 'styled-components';

import { tablet } from '@constants/media-queries';

import SignInForm from './SignInForm';

const Component = styled.div`
  width: 320px;

  ${tablet} {
    width: 450px;
  }
`;

const DialogLogin = () => {
  return (
    <Component>
      <SignInForm />
    </Component>
  );
};

export default DialogLogin;
