import {
  faArrowLeftLong,
  faPlus,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import styled from 'styled-components';

import DialogTemplateSpreads from '@components/DialogTemplateSpreads';
import SpreadPreview from '@components/SpreadPreview';
import {
  ctaOutline,
  cta,
  fontDark,
  lineLight,
  fontMedium,
} from '@constants/colors';
import { space } from '@constants/spaces';
import Title from '@elements/Title';
import { useBookContext } from '@hooks/useBookContext';
import useMediaQuery from '@hooks/useMediaQuery';
import { getSpreadName } from '@utils/string';

const Component = styled.div<{ open: boolean }>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  background-color: white;
  color: ${fontDark};
  display: flex;
  flex-direction: column;
  z-index: 120;
  transition: transform 300ms ease-out;

  h3 {
    flex: 1 0 auto;
  }
`;

const Container = styled.div`
  overflow-y: auto;
  padding: 0 ${space * 4}px ${space * 4}px;
`;

const BackLink = styled.button`
  color: ${cta};
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${space}px;
  background-color: white;
  margin-top: ${space * 8}px;
  &:hover {
    color: ${ctaOutline};
    cursor: pointer;
  }
`;

const SpreadList = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  gap: ${space * 2}px;
  max-width: 400px;
`;

const Top = styled.div`
  display: flex;
  padding: ${space * 3}px ${space * 4}px;
`;

const Close = styled.button`
  color: ${fontDark};
  background: none;
  cursor: pointer;
  padding: ${space / 2}px ${space}px;
  font-size: 16px;
`;

const StyledButton = styled.button`
  width: 100%;
  max-width: 400px;
  display: flex;
  gap: ${space}px;
  align-items: center;
  justify-content: center;
  margin: ${space * 2}px auto;
  font-size: 16px;
  color: ${fontMedium};
  font-weight: 500;
  padding: ${space * 2}px ${space * 3}px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid ${lineLight};
  box-shadow: 0px 0px 1px 0px rgba(10, 22, 70, 0.03),
    0px 3px 3px -1px rgba(10, 22, 70, 0.05);
`;

type Props = {
  open: boolean;
  onClose: () => void;
};

const MobileSpreadList = ({ open, onClose }: Props) => {
  const { book } = useBookContext();
  const [showSpreadsDialog, setShowSpreadsDialog] = useState('');
  const { isLaptop } = useMediaQuery();
  const { isAdmin, isOwner } = useBookContext();
  const showAddSpread = !isLaptop && ((book?.isTemplate && isAdmin) || isOwner);

  if (!book) return null;

  // Determine if user is on a template page
  const isOnTemplatePage = false;

  // Filter out the hidden template spreads, so only visible spreads are included
  const visibleSpreads = book.spreads.filter(
    (spread) => !(!isOnTemplatePage && spread.isTemplate),
  );

  // Counter for non-template spreads, used to generate page numbers for non-template spreads
  let nonTemplateSpreadCounter = 0;

  return (
    <Component open={open}>
      <Top>
        <Title as="h3" size="sm">
          Alle pagina&apos;s
        </Title>
        <Close onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </Close>
      </Top>
      <Container>
        <SpreadList>
          {visibleSpreads.map((spread, visibleIndex) => {
            // Check if the spread is a template
            const isTemplateSpread = spread.isTemplate;

            // Generate the title for non-template spreads with page number
            const title = isTemplateSpread
              ? getSpreadName(
                  visibleIndex,
                  visibleSpreads.length,
                  isTemplateSpread,
                )
              : getSpreadName(
                  nonTemplateSpreadCounter++,
                  visibleSpreads.length,
                  isTemplateSpread,
                );

            return (
              <SpreadPreview
                key={spread.id}
                title={title}
                spread={spread}
                book={book}
                disabled={!spread.isEditable}
                onSpreadClick={onClose}
                isMobile={true}
              />
            );
          })}
        </SpreadList>
        {showAddSpread && (
          <StyledButton onClick={() => setShowSpreadsDialog('show')}>
            <FontAwesomeIcon icon={faPlus} />
            Pagina toevoegen
          </StyledButton>
        )}
        <BackLink onClick={onClose}>
          <FontAwesomeIcon icon={faArrowLeftLong} />
          Terug naar boek
        </BackLink>
      </Container>
      {showSpreadsDialog && (
        <DialogTemplateSpreads
          open={showSpreadsDialog}
          closeDialog={() => setShowSpreadsDialog('')}
        />
      )}
    </Component>
  );
};

export default MobileSpreadList;
