import Link from 'next/link';
import styled from 'styled-components';

import EditorHeader from '@components/EditorHeader';
import MobileHeader from '@components/mobile/MobileHeader';
import { pricing } from '@constants/routes';
import { useBookContext } from '@hooks/useBookContext';
import useMediaQuery from '@hooks/useMediaQuery';
import zindex from '@utils/zindex';

import Button from './Button';

const Component = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: ${zindex('dialog')};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

type DialogProps = {
  open?: boolean;
  children?: React.ReactNode;
  showOrderButton?: boolean;
};

const DialogFullPage = ({
  children = null,
  showOrderButton = false,
}: DialogProps) => {
  const { isTablet } = useMediaQuery();
  const { book } = useBookContext();

  return (
    <Component>
      {isTablet ? (
        <MobileHeader />
      ) : (
        <EditorHeader>
          {showOrderButton && (
            <Link href={pricing(book.id)}>
              <a>
                <Button set="primary">Bestellen</Button>
              </a>
            </Link>
          )}
        </EditorHeader>
      )}
      {children}
    </Component>
  );
};

export default DialogFullPage;
