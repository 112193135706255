import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import zindex from '@utils/zindex';

type DropdownWrapperProps = {
  children: React.ReactNode;
  dropdownChildren: React.ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
  align?: 'left' | 'right' | 'center' | 'top' | 'bottom';
  height?: string;
  hideOnClick?: boolean;
  showMode?: 'onClick' | 'onHover';
  title?: string;
  customPosition?: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };
};

const DropdownWrapper = ({
  children,
  dropdownChildren,
  position = 'bottom',
  align = 'right',
  height = 'auto',
  hideOnClick = true,
  showMode = 'onClick',
  customPosition,
  ...props
}: DropdownWrapperProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const clickDropdownHandler = (e: React.MouseEvent<HTMLElement>) => {
    if (hideOnClick) return;
    e.stopPropagation();
  };

  const wrapperBoundings =
    wrapperRef.current! && wrapperRef.current.getBoundingClientRect();
  const pos = useMemo(() => {
    if (!wrapperRef.current) return {};
    return {
      positionLeft: window.innerWidth - wrapperBoundings.left,
      positionRight: wrapperBoundings.right,
      positionTop: window.innerHeight - wrapperBoundings.top,
      positionBottom: wrapperBoundings.bottom,
      alignLeft: wrapperBoundings.left,
      alignRight: window.innerWidth - wrapperBoundings.right,
      alignTop: wrapperBoundings.top,
      alignBottom: window.innerHeight - wrapperBoundings.bottom,
    };
  }, [wrapperBoundings]);

  const clickHandler = () => {
    setOpen(!open);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <Component
      onClick={showMode === 'onClick' ? clickHandler : () => {}}
      onMouseEnter={showMode === 'onHover' ? clickHandler : () => {}}
      ref={wrapperRef}
      height={height}
      {...props}
    >
      {children}
      <Dropdown
        onClick={clickDropdownHandler}
        open={open}
        location={pos}
        position={`position-${position}`}
        align={`align-${align}`}
        customPosition={customPosition}
      >
        {dropdownChildren}
      </Dropdown>
    </Component>
  );
};

export default DropdownWrapper;

type ComponentProps = {
  height: string;
};

type DropdownProps = {
  open: boolean;
  position:
    | 'position-top'
    | 'position-bottom'
    | 'position-left'
    | 'position-right';
  align:
    | 'align-left'
    | 'align-right'
    | 'align-center'
    | 'align-top'
    | 'align-bottom';
  location: {
    positionTop?: number;
    positionBottom?: number;
    positionRight?: number;
    positionLeft?: number;
    alignTop?: number;
    alignBottom?: number;
    alignRight?: number;
    alignLeft?: number;
  };
  customPosition?: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };
};

const positionVariant = {
  'position-top': css<DropdownProps>`
    bottom: ${(props) => props.location.positionTop! + 8}px;
    transform: translateY(-10%);
  `,
  'position-bottom': css<DropdownProps>`
    top: ${(props) => props.location.positionBottom! + 8}px;
    transform: translateY(-10%);
  `,
  'position-right': css<DropdownProps>`
    left: ${(props) => props.location.positionRight! + 8}px;
    transform: translateX(-10%);
  `,
  'position-left': css<DropdownProps>`
    right: ${(props) => props.location.positionLeft! + 8}px;
    transform: translateX(10%);
  `,
};

const alignVariant = {
  'align-left': css<DropdownProps>`
    left: ${(props) => props.location.alignLeft}px;
  `,
  'align-right': css<DropdownProps>`
    right: ${(props) => props.location.alignRight}px;
  `,
  'align-top': css<DropdownProps>`
    top: ${(props) => props.location.alignTop}px;
  `,
  'align-bottom': css<DropdownProps>`
    bottom: ${(props) => props.location.alignBottom}px;
  `,
  'align-center': css<DropdownProps>``,
};

const Component = styled.div<ComponentProps>`
  height: ${(props) => props.height};
  position: relative;
`;

const Dropdown = styled.div<DropdownProps>`
  position: fixed;
  z-index: ${zindex('dropdown')};
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 1px 0px rgba(10, 22, 70, 0.06),
    0px 16px 16px -1px rgba(10, 22, 70, 0.1);
  pointer-events: none;
  opacity: 0;
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  will-change: transform, opacity;

  ${({ customPosition, position, location }) =>
    customPosition
      ? css`
          top: ${customPosition.top || 'auto'};
          bottom: ${customPosition.bottom || 'auto'};
          left: ${customPosition.left || 'auto'};
          right: ${customPosition.right || 'auto'};
        `
      : positionVariant[position || 'position-bottom']};

  ${({ align, customPosition }) =>
    !customPosition && alignVariant[align || 'align-right']};

  ${({ open }) =>
    open &&
    css`
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
    `};
`;
