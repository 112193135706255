import { useEffect, useState } from 'react';

const useMediaMatches = (query: string) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);

    const listener = () => {
      setMatches(media.matches);
    };

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    media.addEventListener('change', listener);

    return () => {
      media.removeEventListener('change', listener);
    };
  }, [query, matches]);

  return matches;
};

export default useMediaMatches;
