import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

import { fontDark } from '@constants/colors';
import { laptop, smallTablet } from '@constants/media-queries';
import { wrapperWidth } from '@constants/sizes';
import { space } from '@constants/spaces';

const Header = styled.header<{ backgroundColor?: string }>`
  color: ${fontDark};
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: ${space * 3}px;
  padding-right: ${space * 3}px;

  ${laptop} {
    padding-left: ${space * 5}px;
    padding-right: ${space * 4}px;
  }

  li {
    list-style: none;
  }
`;

const Nav = styled.nav`
  margin: 0 auto;
  max-width: ${wrapperWidth};
`;

const NavList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  position: relative;
  justify-content: space-between;
`;

const LogoItem = styled.li`
  order: 1;
  width: 140px;

  ${smallTablet} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 140px;
  }

  ${laptop} {
    order: 0;
    position: static;
    transform: translate(0, 0);
    width: 192px;
    margin-top: 2px;

    img {
      height: 18px;
    }
  }
`;

type Props = {
  children: React.ReactNode;
  backgroundColor?: string;
};

const TopBar = ({ children, backgroundColor, ...rest }: Props) => {
  return (
    <Header backgroundColor={backgroundColor} {...rest}>
      <Nav>
        <NavList>
          <LogoItem>
            <Link href="/">
              <a>
                <div>
                  <Image
                    src="/images/logo.svg"
                    alt="Belmodofoto logo"
                    width={192}
                    height={22}
                  />
                </div>
              </a>
            </Link>
          </LogoItem>
          {children}
        </NavList>
      </Nav>
    </Header>
  );
};

export default TopBar;
