import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { useMemo } from 'react';
import styled from 'styled-components';

import { fontDark, lineLight } from '@constants/colors';
import { maxWidth } from '@constants/media-queries';
import { space } from '@constants/spaces';
import zIndex from '@utils/zindex';

const TextLinks = styled.ul<{ open: boolean }>`
  ${maxWidth.tablet} {
    font-size: 16px;
    padding: ${space * 2}px ${space * 3}px;
    border-right: 1px solid ${lineLight};
    position: fixed;
    background-color: white;
    top: 0;
    left: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 220px;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
    transition: transform 300ms ease-out 150ms;
    z-index: ${zIndex('side-menu') + 1};
  }
`;

const Overlay = styled.div<{ open: boolean }>`
  ${maxWidth.tablet} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(10, 31, 68, 0.5);
    transition: opacity 0.3s ease;
    opacity: ${({ open }) => (open ? 1 : 0)};
    pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
    z-index: ${zIndex('side-menu')};
  }
`;

const CloseButton = styled.div`
  font-size: 20px;
  position: absolute;
  top: ${space * 2}px;
  right: ${space * 2}px;
  cursor: pointer;
`;

const TextLink = styled.li`
  margin-bottom: ${space * 3}px;
  & > a {
    text-decoration: none;
    line-height: 32px;
    &:hover {
      color: ${fontDark};
    }
  }
`;

type MobileNavProps = {
  showNav: boolean;
  toggleShowNav: () => void;
  navigation: { label: string; href: string; external?: boolean }[];
  accountLinks: { label: string; href: string; external?: boolean }[];
};

const MobileNavigation = ({
  showNav,
  toggleShowNav,
  navigation,
  accountLinks,
}: MobileNavProps) => {
  const renderLinks = useMemo(
    () =>
      [...accountLinks, ...navigation].map((link, i) => (
        <TextLink key={i}>
          {link.external ? (
            <a href={link.href} target="_blank" rel="noopener noreferrer">
              {link.label}
            </a>
          ) : (
            <Link href={link.href}>{link.label}</Link>
          )}
        </TextLink>
      )),
    [accountLinks, navigation],
  );

  return (
    <>
      <TextLinks open={showNav} onClick={toggleShowNav}>
        <CloseButton onClick={toggleShowNav}>
          <FontAwesomeIcon icon={faClose} aria-label="Menu sluiten" />
        </CloseButton>
        {renderLinks}
      </TextLinks>
      <Overlay open={showNav} onClick={toggleShowNav} />
    </>
  );
};

export default MobileNavigation;
