import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @font-face {
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-weight: 400;
    font-display: $fa-font-display;
    src: url('/fonts/fa-regular-400.eot');
    src: url('/fonts/fa-regular-400.eot?#iefix') format('embedded-opentype'),
    url('/fonts/fa-regular-400.woff2') format('woff2'),
    url('/fonts/fa-regular-400.woff') format('woff'),
    url('/fonts/fa-regular-400.ttf') format('truetype'),
    url('/fonts/fa-regular-400.svg#fontawesome') format('svg');
  }
  
  @font-face {
    font-family: 'Font Awesome 5 Pro Light';
    font-style: normal;
    font-weight: 300;
    font-display: $fa-font-display;
    src: url('/fonts/fa-light-300.eot');
    src: url('/fonts/fa-light-300.eot?#iefix') format('embedded-opentype'),
    url('/fonts/fa-light-300.woff2') format('woff2'),
    url('/fonts/fa-light-300.woff') format('woff'),
    url('/fonts/fa-light-300.ttf') format('truetype'),
    url('/fonts/fa-light-300.svg#fontawesome') format('svg');
  }
`;
