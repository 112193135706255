const components = [
  'fake',
  'photobook-pages',
  'element',
  'element-move',
  'moveable',
  'info',
  'photobook-bleed',
  'element-menu',
  'inside-cover',
  'photobook-nav',
  'spine',
  'locked-page',
  'barcode',
  'page-preview-menu',
  'float-menu',
  'photobook-notes',
  'toolbar',
  'sidebar',
  'side-menu',
  'sticky-header',
  'dropdown',
  'popover',
  'dialog',
  'no-mobile',
  'cookies',
  'fullscreen-dropzone',
  'alert',
  'resolution-check',
];

const zindex = (name: string) => components.indexOf(name) + 100;

export default zindex;
