import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';
import styled, { css } from 'styled-components';

import { brand, lineLight } from '@constants/colors';
import { tablet } from '@constants/media-queries';
import { book, templateCollaborativePages } from '@constants/routes';
import { space } from '@constants/spaces';
import templateSpreadsProd from '@constants/template-spreads-prod.json';
import templateSpreads from '@constants/template-spreads.json';
import Button from '@elements/Button';
import Dialog from '@elements/Dialog';
import Title from '@elements/Title';
import {
  GqlBookElement,
  useBookSpreadToggleCollaborativeMutation,
  useSpreadAddMutation,
} from '@graphql/generated/graphql';
import { useCreateElement } from '@hooks/useCreateElement';

const Component = styled.div`
  padding: ${space * 2}px ${space * 2}px;
  width: 300px;

  ${tablet} {
    width: 723px;
  }
`;

const Templates = styled.div<{ isMultiLine?: boolean }>`
  margin: ${space * 4}px 0;
  max-height: 400px;
  gap: ${space}px;
  overflow: ${({ isMultiLine }) => isMultiLine && 'auto'};
  display: flex;
  flex-direction: column;

  ${tablet} {
    max-height: 455px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
    grid-column-gap: 8px;
    grid-row-gap: 8px;
  }
`;

const PreviewPage = styled.div<{ isOne?: boolean; active: boolean }>`
  background-color: white;
  border: ${({ active }) => `2px solid ${active ? brand : lineLight}`};
  border-radius: 10px;
  position: relative;
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  ${({ isOne }) =>
    isOne &&
    css`
      width: 401px;
      margin: 0 auto;
    `};

  img {
    border-radius: 8px;
  }
`;

const Buttons = styled.div`
  margin-top: ${space * 3}px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;

  ${tablet} {
    justify-content: space-between;
  }
`;

type Props = {
  open: string;
  closeDialog: () => void;
};

const DialogTemplateSpreads = ({ open, closeDialog }: Props) => {
  const [chosenSpread, setChosenSpread] = useState<number | null>(null);
  const router = useRouter();
  const { bookId } = router.query;
  const [spreadAdd, { loading }] = useSpreadAddMutation();
  const { createElement } = useCreateElement();
  const [bookSpreadToggleCollaborative] =
    useBookSpreadToggleCollaborativeMutation();
  const isOnTemplatePage =
    window.location.pathname ===
    templateCollaborativePages(
      router.query.bookId as string,
      router.query.spreadId as string,
    );
  const createSpread = () => {
    spreadAdd({
      variables: {
        bookId,
      },
      onCompleted: (data) => {
        const selectedTemplateSpreads =
          process.env.NODE_ENV === 'production'
            ? templateSpreadsProd
            : templateSpreads;
        selectedTemplateSpreads[chosenSpread!].elements.forEach((element) =>
          createElement({
            bookId: bookId as string,
            spreadId: data.bookSpreadAdd.id,
            element: element as GqlBookElement,
          }),
        );
        isOnTemplatePage &&
          bookSpreadToggleCollaborative({
            variables: { spreadId: data.bookSpreadAdd.id },
          });
        router.push(
          isOnTemplatePage
            ? templateCollaborativePages(
                bookId as string,
                data.bookSpreadAdd.id,
              )
            : book(bookId as string, data.bookSpreadAdd.id),
        );
        closeDialog();
      },
    });
  };

  const templatePreviews = templateSpreads.map((_, i) => {
    return (
      <PreviewPage
        key={i}
        isOne={templateSpreads.length === 1}
        onClick={() => setChosenSpread(i)}
        active={chosenSpread === i}
      >
        <Image
          src={`/images/templates/template_${i}.png`}
          alt={`Sjabloon spread ${i + 1}`}
          layout="responsive"
          width={379}
          height={195}
        />
      </PreviewPage>
    );
  });

  return (
    <Dialog open={open} closeDialog={closeDialog}>
      <Component>
        <Title size="sm" as="h3" weight={800}>
          Kies pagina opmaak
        </Title>
        <Templates isMultiLine={templateSpreads.length > 2}>
          {templatePreviews}
        </Templates>
        <Buttons>
          <Button onClick={closeDialog} set="tertiary">
            Annuleren
          </Button>
          <Button
            onClick={createSpread}
            disabled={loading || chosenSpread === null}
          >
            Kiezen
          </Button>
        </Buttons>
      </Component>
    </Dialog>
  );
};

export default DialogTemplateSpreads;
