import striptags from 'striptags';

export const striptLegacyHTML = (html: string) => {
  let newHTML = striptags(html, ['br'], '\n');

  newHTML = striptags(newHTML);
  newHTML = newHTML.replace('&nbsp;', ' ');

  return newHTML;
};

export const getSpreadName = (
  spreadIndex: number,
  length: number,
  isTemplateSpread: boolean,
  showTemplateTitle?: boolean,
) => {
  let title = 'Achterkant - Voorkant';
  if (isTemplateSpread) title = showTemplateTitle ? 'Sjabloon' : '';

  if (spreadIndex > 0)
    title = `${spreadIndex * 2 - 2} - ${spreadIndex * 2 - 1}`;
  if (spreadIndex === 1) title = `${spreadIndex * 2 - 1}`;
  if (length && spreadIndex === length - 1) title = `${spreadIndex * 2 - 2}`;

  return title;
};

export const getSpreadNameMobile = (spreadIndex: number, length: number) => {
  let title = 'A - V';

  if (spreadIndex > 0)
    title = `${spreadIndex * 2 - 2} - ${spreadIndex * 2 - 1}`;
  if (spreadIndex === 1) title = ` - ${spreadIndex * 2 - 1}`;
  if (length && spreadIndex === length - 1) title = `${spreadIndex * 2 - 1} - `;

  return title.split(' - ');
};

export const getTemplateSpreadNameMobile = (spreadIndex: number) => {
  return `Sjabloon ${spreadIndex + 1}`;
};
