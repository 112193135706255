import { faCheck, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from 'styled-components';

import { fontMedium } from '@constants/colors';
import { space } from '@constants/spaces';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${space}px;
  background-color: white;
  color: ${fontMedium};

  &:hover {
    cursor: pointer;
  }
`;

function SaveButton() {
  const [isLoading, setLoading] = useState(false);

  const handleSave = () => {
    setLoading(true);

    // Show opslaan...
    setTimeout(() => {
      setLoading(false);
    }, 750);
  };

  return (
    <StyledButton onClick={handleSave} disabled={isLoading}>
      <FontAwesomeIcon
        icon={isLoading ? faSpinnerThird : faCheck}
        spin={isLoading}
      />
      {isLoading ? 'Opslaan...' : 'Opgeslagen'}
    </StyledButton>
  );
}

export default SaveButton;
