import { createContext, useState } from 'react';

type ContextProps = {
  open: boolean;
  type: string;
  openDialog: (type: string, args?: {}) => void;
  closeDialog: () => void;
  info?: any;
};

const DialogContext = createContext({} as ContextProps);

type Props = {
  children: React.ReactNode;
};

export const DialogProvider = ({ children }: Props) => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');
  const [info, setInfo] = useState(null);

  const openDialog = (type: string, args: any) => {
    setOpen(true);
    setType(type);
    setInfo(args);
  };

  const closeDialog = () => {
    setOpen(false);
    setType('');
  };

  const value = {
    open,
    type,
    openDialog,
    closeDialog,
    info,
  };

  return (
    <DialogContext.Provider value={value}>{children}</DialogContext.Provider>
  );
};

export default DialogContext;
