import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import { darken } from 'polished';
import styled from 'styled-components';

import { brand } from '@constants/colors';

type ComponentProps = {
  size: number;
  backgroundColor?: string;
};

const Component = styled.div<ComponentProps>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: 0px 0px 1px 0px rgba(10, 22, 70, 0.06),
    0px 6px 6px -1px rgba(10, 22, 70, 0.1);
  transition: background-color 300ms;
  position: relative;
  overflow: hidden;

  &:hover {
    background-color: ${({ backgroundColor }) =>
      darken(0.07, backgroundColor || brand)};
  }
`;

type AvatarProps = {
  name?: string;
  image?: string;
  icon?: IconLookup;
  size?: number;
  backgroundColor?: string;
};

const Avatar = ({
  name,
  image,
  icon,
  size = 40,
  backgroundColor = brand,
  ...rest
}: AvatarProps) => {
  let content: any = '?';
  if (name) content = getInitials(name || '');
  if (image)
    content = (
      <Image src={image} alt="User pic" layout="fill" unoptimized={true} />
    );
  if (icon) content = <FontAwesomeIcon icon={icon} />;

  return (
    <Component
      size={size}
      backgroundColor={image ? '' : backgroundColor}
      {...rest}
    >
      {content}
    </Component>
  );
};

export default Avatar;

const getInitials = (string: string) => {
  let initials = '';
  if (string) {
    const words = string.split(' ');
    words.forEach((word) => {
      if (!word[0]) return;
      // add first letter of each word
      initials += word[0];
    });

    return initials || '?';
  }

  return '?';
};
