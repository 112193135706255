import styled, { css } from 'styled-components';

import { laptop } from '@constants/media-queries';

type TextProps = {
  size?: 'sm' | 'md' | 'nm' | 'lg';
  weight?: 400 | 500 | 600 | 800;
};

const variantStyles = {
  lg: css`
    font-size: 20px;
    font-weight: 500;
    line-height: 36px;
    ${laptop} {
      font-size: 24px;
    }
  `,
  md: css`
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    ${laptop} {
      font-size: 20px;
    }
  `,
  nm: css`
    font-size: 16px;
    line-height: 28px;
  `,
  sm: css`
    font-size: 14px;
    line-height: 20px;
  `,
};

const Text = styled.p<TextProps>`
  ${({ size }) => variantStyles[size || 'md']};
  font-weight: ${({ weight }) => weight};
`;

export default Text;
