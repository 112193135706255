import QRCode from 'qrcode.react';
import styled from 'styled-components';

import { fontDark, fontLight, lineLight } from '@constants/colors';
import { space } from '@constants/spaces';

const QRCodeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const SeparatorText = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: ${space * 4}px 0;

  .line {
    flex: 1;
    height: 1px;
    background-color: ${lineLight};
  }

  span {
    white-space: nowrap;
    font-weight: 500;
    color: ${fontLight};
    padding: 0 ${space * 2}px;
  }
`;

type InviteLinkQrCodeProps = {
  inviteUrl: (bookId: string, token: string) => string;
  bookId: string;
  inviteToken: { bookInvitationToken?: string };
};

const InviteLinkQrCode = ({
  inviteUrl,
  bookId,
  inviteToken,
}: InviteLinkQrCodeProps) => {
  const qrCodeValue = `${
    window.location.origin
  }/uitnodigen/copy-link?bookLink=${encodeURIComponent(
    inviteUrl(bookId, inviteToken?.bookInvitationToken || ''),
  )}`;

  return (
    <QRCodeContainer>
      <QRCode size={100} fgColor={`${fontDark}`} value={qrCodeValue} />
      <SeparatorText>
        <div className="line"></div>
        <span>Scan de QR of kopieer de link hieronder</span>
        <div className="line"></div>
      </SeparatorText>
    </QRCodeContainer>
  );
};

export default InviteLinkQrCode;
