import { lighten } from 'polished';
import styled, { css } from 'styled-components';

import {
  backgroundMedium,
  fontLight,
  info,
  infoOutline,
  success,
  successOutline,
  warningOutline,
} from '@constants/colors';
import { space } from '@constants/spaces';

type BadgeProps = {
  set?: 'default' | 'success' | 'warning' | 'info';
};

const setVariant = {
  default: css`
    background: ${backgroundMedium};
    color: ${fontLight};
  `,
  success: css`
    background: ${lighten(0.6, successOutline)};
    color: ${success};
  `,
  warning: css`
    background: ${lighten(0.35, warningOutline)};
    color: ${warningOutline};
  `,
  info: css`
    background: ${lighten(0.65, infoOutline)};
    color: ${info};
  `,
};

const Badge = styled.span<BadgeProps>`
  padding: ${space}px ${space * 2}px;
  ${({ set }) => setVariant[set || 'default']};
  text-align: center;
  word-break: break-word;
  vertical-align: middle;
  border-radius: 21px;
  white-space: nowrap;

  & svg {
    margin-right: 4px;
  }
`;

export default Badge;
