import styled from 'styled-components';

import { tablet } from '@constants/media-queries';

import SignUpForm from './SignUpForm';

const Component = styled.div`
  width: 320px;

  ${tablet} {
    width: 450px;
  }
`;

const DialogSignup = () => {
  return (
    <Component>
      <SignUpForm />
    </Component>
  );
};

export default DialogSignup;
