/**
 * Percentage of container inside to window to calc the font size
 * @param {number} windowWidth
 * @param {number} containerWidth
 * @returns {number}
 */
const calcContainerFontSize = (
  windowWidth: number = 1,
  containerWidth: number = 1,
): number => Math.round((containerWidth / windowWidth) * 100);

export default calcContainerFontSize;
