export const isBrowser = typeof window !== 'undefined';

export const isStaging = () => {
  const subdomains = isBrowser && window.location.hostname.split('.');
  const showCta =
    subdomains &&
    (subdomains.includes('localhost') || subdomains.includes('staging'));
  return showCta;
};

export const comingDate = () => {
  const objDate = new Date();
  objDate.setMonth(objDate.getMonth() + 1);
  return objDate.toLocaleString('nl-nl', { month: 'long' });
};
