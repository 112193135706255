import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

import { lineLight, placeholder } from '@constants/colors';
import { newContainerAlignment } from '@constants/features';
import {
  BookElementContainer,
  BookElementFrame,
  BookElementSticker,
  BookElementText,
  BookSpread,
  ElementType,
  Mask,
} from '@graphql/generated/graphql';
import useMediaQuery from '@hooks/useMediaQuery';
import calcContainerFontSize from '@utils/font-size';

const Component = styled.canvas`
  width: 100%;
  display: block;
`;

const rotateElement = (
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  deg: number,
) => {
  const rad = (deg * Math.PI) / 180;
  const centerX = x + width / 2;
  const centerY = y + height / 2;

  //Set the origin to the center of the image
  context.translate(centerX, centerY);

  //Rotate the canvas around the origin
  context.rotate(rad);

  context.translate(-centerX, -centerY);

  // Restore canvas state as saved from above
  // context.restore();
};

type DrawImageProps = {
  context: CanvasRenderingContext2D;
  image: CanvasImageSource;
  x: number;
  y: number;
  width: number;
  height: number;
  rotation?: number;
};

const drawImage = ({
  context,
  image,
  x,
  y,
  width,
  height,
  rotation,
}: DrawImageProps) => {
  context.save(); // Save the current state

  if (rotation) {
    // Translate to the center of the image
    const centerX = x + width / 2;
    const centerY = y + height / 2;
    context.translate(centerX, centerY);

    // Rotate the canvas around the center of the image
    context.rotate((rotation * Math.PI) / 180);

    // Translate back
    context.translate(-centerX, -centerY);
  }

  // Draw the image
  context.drawImage(image, x, y, width, height);
  context.restore(); // Restore the original state
};

const imageCache = new Map();

const renderImage = (
  context: CanvasRenderingContext2D,
  url: string,
  x: number,
  y: number,
  width: number,
  height: number,
  rotation?: number,
) =>
  new Promise<void>((resolve, reject) => {
    // Check if the image is already in the cache
    if (imageCache.has(url)) {
      const cachedImage = imageCache.get(url);
      drawImage({ context, image: cachedImage, x, y, width, height, rotation });
      resolve();
      return;
    }

    // If not in cache, load the image
    const image = new Image();
    image.src = url;
    context.save();

    image.onload = () => {
      // Add the loaded image to the cache
      imageCache.set(url, image);
      drawImage({ context, image, rotation, x, y, width, height });
      resolve();
    };
    image.onerror = (error) => reject(new Error("Canvas image couldn't load"));
  }).catch((err) => console.error(err));

const getPosition = (canvas: HTMLCanvasElement, x: number, y: number) => {
  const { width, height } = canvas.getBoundingClientRect();

  return {
    x: (width / 100) * x,
    y: (height / 100) * y,
  };
};

const getSize = (
  canvas: HTMLCanvasElement,
  elementWidth: number,
  elementHeight: number,
) => {
  const { width, height } = canvas.getBoundingClientRect();

  return {
    width: (width / 100) * elementWidth,
    height: (height / 100) * elementHeight,
  };
};

const wrapText = (
  context: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  maxWidth: number,
  lineHeight: number,
  textDecoration: string,
) => {
  const words = text && text.split(' ');

  if (!words) return;

  const decoration =
    textDecoration === 'underline'
      ? 1
      : textDecoration === 'line-through'
      ? 2
      : null;
  let line = '';
  let calcY = y;

  for (let n = 0; n < words.length; n++) {
    const testLine = `${line + words[n]} `;
    const testMeasure = context.measureText(testLine);

    if (testMeasure.width > maxWidth && n > 0) {
      const lineMeasure = context.measureText(line);
      context.fillText(line, x, calcY);
      if (decoration)
        context.fillRect(
          x,
          calcY + lineMeasure.actualBoundingBoxDescent / decoration,
          lineMeasure.width,
          1,
        );

      line = `${words[n]} `;
      calcY += lineHeight;
    } else {
      line = testLine;
    }
  }

  const lineMeasure = context.measureText(line);
  context.fillText(line, x, calcY);
  if (decoration)
    context.fillRect(
      x,
      calcY + lineMeasure.actualBoundingBoxDescent / decoration,
      lineMeasure.width,
      1,
    );
};

const resizeCanvas = (
  canvas: HTMLCanvasElement,
  context: CanvasRenderingContext2D,
) => {
  const { width, height } = canvas.getBoundingClientRect();

  if (canvas.width !== width || canvas.height !== height) {
    const { devicePixelRatio: ratio = 1 } = window;
    canvas.width = width * ratio;
    canvas.height = (width / 2) * ratio;
    context.scale(ratio, ratio);
  }
};

type Props = {
  spread: BookSpread;
  isHidden?: boolean;
};

const SpreadCanvas = ({ spread, isHidden }: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [contextRef, setContextRef] = useState<CanvasRenderingContext2D>();
  const { isTablet } = useMediaQuery();

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    if (!context) return;
    setContextRef(context);
    resizeCanvas(canvas, context);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = contextRef;

    if (!canvas || !context) return;

    const bounds = canvas?.getBoundingClientRect();
    const { elements, background } = spread;

    (async () => {
      // Clear canvas
      context.clearRect(0, 0, canvas.width, canvas.height);

      // Draw backgrounds
      if (background?.left?.image?.url)
        await renderImage(
          context,
          background.left.image.url,
          0,
          0,
          bounds.width / 2,
          bounds.height,
        );
      if (background?.right?.image?.url)
        await renderImage(
          context,
          background.right.image.url,
          bounds.width / 2,
          0,
          bounds.width / 2,
          bounds.height,
        );

      // Draw line in center of the book
      context.fillStyle = lineLight;
      context.fillRect(bounds.width / 2 - 1, 0, 2, bounds.height);

      if (elements) {
        for (let element of elements) {
          const { x, y } = getPosition(canvas, element.left, element.top);
          const { width, height } = getSize(
            canvas,
            element.width,
            element.height,
          );

          if (element.type === ElementType.Text && !isTablet) {
            element = element as BookElementText;
            // 14 is de default font size
            const fontSize =
              calcContainerFontSize(window.innerWidth, bounds.width) *
              14 *
              (element.fontSize / 100);

            context.save();
            context.beginPath();
            context.rect(x, y, width, height);
            rotateElement(context, x, y, width, height, element.rotation);
            context.font = `${element.fontStyle} ${
              element.fontWeight || 'normal'
            } ${fontSize}px ${element.fontFamily}`;
            context.textAlign = element.textAlign as CanvasTextAlign;
            context.fillStyle = element.color;
            context.textBaseline = 'top';

            // Align position
            let calcX = x;
            calcX = element.textAlign === 'center' ? x + width / 2 : calcX;
            calcX = element.textAlign === 'right' ? x + width : calcX;

            // 1.2 is the default line-height for most browser
            if (isHidden) {
              context.filter = 'blur(5px)';
            }
            wrapText(
              context,
              element.content || element.placeholder,
              calcX,
              y,
              width,
              fontSize * 1.2,
              element.textDecoration,
            );
            context.restore();
          }

          if (element.type === ElementType.Sticker) {
            element = element as BookElementSticker;
            await renderImage(
              context,
              element.image.url,
              x,
              y,
              width,
              height,
              element.rotation,
            );
          }

          if (element.type === ElementType.Container) {
            element = element as BookElementContainer;
            context.save();
            context.beginPath();

            if (element.round) {
              const radiusWidth = width / 2;
              const radiusHeight = height / 2;
              context.ellipse(
                x + radiusWidth,
                y + radiusHeight,
                radiusWidth,
                radiusHeight,
                0,
                0,
                2 * Math.PI,
              );
            } else {
              context.rect(x, y, width, height);
            }

            // TODO: why clip()?
            context.clip();
            // rotateElement(context, x, y, width, height, element.rotation)
            if (element.image.url) {
              // image offset inside the container
              const changeAlignment = newContainerAlignment(element.createdAt);
              const offsetLeft = element.imageLeft * (width / 100);
              const offsetTop = element.imageTop * (height / 100);
              const imageWidth = width * (element.imageWidth / 100);
              const imageHeight = imageWidth / (element.image.aspectRatio || 0);
              const offsetWidth = imageWidth / 2;
              const offsetHeight = imageHeight / 2;

              await renderImage(
                context,
                element.image.url,
                changeAlignment ? x + offsetLeft - offsetWidth : x + offsetLeft,
                changeAlignment ? y + offsetTop - offsetHeight : y + offsetTop,
                imageWidth,
                imageHeight,
                //TODO: cut off = switch width vs height or remove clip()?
                // element.rotation,
              );
            } else {
              context.fillStyle = placeholder;
              context.fill();
            }
            context.restore();
          }

          if (element.type === ElementType.Frame) {
            element = element as BookElementFrame;
            context.save();

            if (element.mask === Mask.Circle) {
              const containerWidth = width * 0.76;
              const containerHeight = height * 0.76;
              const radiusWidth = Math.abs(containerWidth / 2);
              const offset = width * 0.12;

              context.beginPath();
              context.arc(
                x + radiusWidth + offset,
                y + radiusWidth + offset,
                radiusWidth,
                0,
                2 * Math.PI,
              );

              if (element.image.url) {
                const changeAlignment = newContainerAlignment(
                  element.createdAt,
                );
                const offsetLeft = element.imageLeft * (containerWidth / 100);
                const offsetTop = element.imageTop * (containerHeight / 100);
                const imageWidth = containerWidth * (element.imageWidth / 100);
                const imageHeight =
                  imageWidth / (element.image.aspectRatio || 0);
                const offsetWidth = imageWidth / 2;
                const offsetHeight = imageHeight / 2;
                context.clip();

                await renderImage(
                  context,
                  element.image.url,
                  changeAlignment
                    ? x + offset + offsetLeft - offsetWidth
                    : x + offset + offsetLeft,
                  changeAlignment
                    ? y + offset + offsetTop - offsetHeight
                    : y + offset + offsetTop,
                  imageWidth,
                  imageHeight,
                  // element.rotation,
                );
              } else {
                context.fillStyle = placeholder;
                context.fill();
              }

              context.restore();
            }

            if (element.mask === Mask.Square) {
              const containerWidth = width * 0.6;
              const containerHeight = height * 0.6;
              const offset = width * 0.2;
              context.beginPath();
              context.rect(
                x + offset,
                y + offset,
                containerWidth,
                containerHeight,
              );

              if (element.image.url) {
                const changeAlignment = newContainerAlignment(
                  element.createdAt,
                );
                const offsetLeft = element.imageLeft * (containerWidth / 100);
                const offsetTop = element.imageTop * (containerHeight / 100);
                const imageWidth = containerWidth * (element.imageWidth / 100);
                const imageHeight =
                  imageWidth / (element.image.aspectRatio || 0);
                const offsetWidth = imageWidth / 2;
                const offsetHeight = imageHeight / 2;
                context.clip();

                await renderImage(
                  context,
                  element.image.url,
                  changeAlignment
                    ? x + offset + offsetLeft - offsetWidth
                    : x + offset + offsetLeft,
                  changeAlignment
                    ? y + offset + offsetTop - offsetHeight
                    : y + offset + offsetTop,
                  imageWidth,
                  imageHeight,
                  // element.rotation,
                );
              } else {
                context.fillStyle = placeholder;
                context.fill();
              }

              context.restore();
            }

            if (element.mask === Mask.Landscape) {
              const containerWidth = width * 0.82;
              const containerHeight = height * 0.6;
              const offsetLeft = width * 0.09;
              const offsetTop = height * 0.2;
              context.beginPath();
              context.rect(
                x + offsetLeft,
                y + offsetTop,
                containerWidth,
                containerHeight,
              );

              if (element.image.url) {
                const changeAlignment = newContainerAlignment(
                  element.createdAt,
                );
                const imageLeft = element.imageLeft * (containerWidth / 100);
                const imageTop = element.imageTop * (containerHeight / 100);
                const imageWidth = containerWidth * (element.imageWidth / 100);
                const imageHeight =
                  imageWidth / (element.image.aspectRatio || 0);
                const offsetWidth = imageWidth / 2;
                const offsetHeight = imageHeight / 2;
                context.clip();

                await renderImage(
                  context,
                  element.image.url,
                  changeAlignment
                    ? x + offsetLeft + imageLeft - offsetWidth
                    : x + offsetLeft + imageLeft,
                  changeAlignment
                    ? y + offsetTop + imageTop - offsetHeight
                    : y + offsetTop + imageTop,
                  imageWidth,
                  imageHeight,
                  // element.rotation,
                );
              } else {
                context.fillStyle = placeholder;
                context.fill();
              }

              context.restore();
            }

            await renderImage(
              context,
              element.frame.url,
              x,
              y,
              width,
              height,
              // element.rotation,
            );
          }
        }
      }
    })();
  }, [contextRef, spread, isHidden, isTablet]);

  return <Component ref={canvasRef} />;
};

export default SpreadCanvas;
