import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';

import { lineLight, backgroundMedium, fontLight } from '@constants/colors';
import { tablet } from '@constants/media-queries';

const ThumbnailWrapper = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  width: 140px;
  height: 140px;
  border: 1px solid ${lineLight};
  border-radius: 2px;
  background-color: ${backgroundMedium};
  color: ${fontLight};
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  box-shadow: 0px 3px 3px -1px #0a16461a;
  position: relative;
  order: 1;

  ${tablet} {
    order: 0;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    width: 4px;
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

const StyledImage = styled(Image)`
  object-fit: cover;
  object-position: 98% center;
  width: 100%;
  height: 100%;
  transform: scale(1.1);
`;

interface BookCoverThumbnailProps {
  thumbnailURL?: string | null;
  altText?: string;
}

const BookCoverThumbnail = ({
  thumbnailURL,
  altText,
}: BookCoverThumbnailProps) => {
  return (
    <ThumbnailWrapper>
      {thumbnailURL ? (
        <StyledImage
          src={thumbnailURL}
          alt={altText || 'Boek cover'}
          width={200}
          height={200}
          layout="intrinsic"
        />
      ) : (
        <p>Voorbeeld beschikbaar na bewerken</p>
      )}
    </ThumbnailWrapper>
  );
};

export default BookCoverThumbnail;
