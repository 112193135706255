import { IconLookup } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import React from 'react';
import styled, { css } from 'styled-components';

import {
  backgroundMedium,
  brand,
  fontDark,
  fontMedium,
  grey300,
  primary200,
} from '@constants/colors';
import { space } from '@constants/spaces';

type MenuItemProps = {
  isActive?: boolean;
  set?: 'primary' | 'secondary';
};

const Component = styled.ul`
  margin: 0;
  padding: ${space * 2}px ${space}px;
`;

const setStyles = {
  primary: css`
    background-color: ${grey300};
    color: ${fontDark};
  `,
  secondary: css`
    border-left-color: ${brand};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: ${backgroundMedium};
  `,
};

const MenuItem = styled.a<MenuItemProps>`
  height: 40px;
  min-width: 184px;
  padding: 0 ${space * 1.5}px;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: 4px;
  transition: 200ms ease;
  font-size: 14px;
  color: ${fontMedium};
  cursor: pointer;
  ${({ set }) => set === 'secondary' && `border-left: 2px solid ${grey300}`};
  ${({ isActive, set }) => isActive && setStyles[set || 'primary']};

  &:hover {
    background-color: ${backgroundMedium};
  }
`;

const Icon = styled.div<MenuItemProps>`
  width: 30px;
  ${({ isActive, set }) =>
    set === 'secondary' && isActive && `color: ${brand}`};
`;

type DropdownMenuProps = {
  items: {
    name: string;
    icon?: IconLookup;
    show?: boolean;
    to?: string;
    href?: string;
    active?: boolean;
    style?: object;
    onClick?: () => void;
  }[];
  set?: 'primary' | 'secondary';
};

const renderItems = (
  items: DropdownMenuProps['items'],
  set: DropdownMenuProps['set'],
) =>
  items.map((item) => {
    if (item.show === false) return null;

    const child = (
      <>
        <Icon isActive={item.active} set={set}>
          {item.icon && <FontAwesomeIcon icon={item.icon} />}
        </Icon>
        {item.name}
      </>
    );

    if (item.to) {
      return (
        <li key={item.name}>
          <Link key={item.name} href={item.to || '#'} passHref>
            <MenuItem style={item.style || {}} isActive={item.active} set={set}>
              {child}
            </MenuItem>
          </Link>
        </li>
      );
    }

    if (item.href) {
      return (
        <li key={item.name}>
          <MenuItem
            href={item.href}
            style={item.style || {}}
            isActive={item.active}
            set={set}
          >
            {child}
          </MenuItem>
        </li>
      );
    }

    if (item.onClick) {
      return (
        <MenuItem
          key={item.name}
          style={item.style || {}}
          onClick={() => item.onClick && item.onClick()}
          isActive={item.active}
          set={set}
          as="li"
        >
          {child}
        </MenuItem>
      );
    }

    return null;
  });

const DropdownMenu = ({
  items,
  set = 'primary',
  ...rest
}: DropdownMenuProps) => (
  <Component {...rest}>{renderItems(items, set)}</Component>
);

export default DropdownMenu;
