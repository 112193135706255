import styled, { css } from 'styled-components';

import { laptop } from '@constants/media-queries';

type TitleProps = {
  size: 'xxl' | 'xl' | 'lg' | 'md' | 'nm' | 'sm' | 'xs' | 'xxs';
  weight?: 400 | 500 | 600 | 800;
};

const variantStyles = {
  xxl: css`
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    ${laptop} {
      font-size: 64px;
      line-height: 72px;
    }
  `,
  xl: css`
    font-size: 40px;
    font-weight: 800;
    line-height: 56px;
    ${laptop} {
      font-size: 56px;
      line-height: 64px;
    }
  `,
  lg: css`
    font-size: 34px;
    font-weight: 800;
    line-height: 42px;
    ${laptop} {
      font-size: 42px;
      font-weight: 800;
      line-height: 50px;
    }
  `,
  md: css`
    font-size: 32px;
    font-weight: 800;
    line-height: 44px;
  `,
  nm: css`
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
  `,
  sm: css`
    font-size: 20px;
    font-weight: 800;
    line-height: 32px;
  `,
  xs: css`
    font-size: 16px;
    font-weight: 600;
  `,
  xxs: css`
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  `,
};

const Title = styled.h1<TitleProps>`
  ${({ size }) => variantStyles[size || 'md']};
  font-weight: ${({ weight }) => weight};
`;

export default Title;
