import { IconLookup } from '@fortawesome/fontawesome-common-types';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled, { css } from 'styled-components';

import {
  cta,
  ctaHover,
  ctaOutline,
  ctaSecHover,
  fontDisabled,
  fontMedium,
  info,
  infoSecHover,
  lineLight,
  success,
  successHover,
} from '@constants/colors';
import { space } from '@constants/spaces';

const setVariant = {
  primary: css`
    color: white;
    background-color: ${cta};
  `,
  secondary: css`
    color: ${ctaOutline};
    background-color: white;
    box-shadow: 0px 0px 1px 0px rgba(10, 22, 70, 0.03),
      0px 3px 3px -1px rgba(10, 22, 70, 0.05);

    &:hover {
      background-color: ${ctaSecHover};
    }
  `,
  tertiary: css`
    color: ${fontMedium};
    background-color: white;
    border-color: ${lineLight};
    box-shadow: 0px 0px 1px 0px rgba(10, 22, 70, 0.03),
      0px 3px 3px -1px rgba(10, 22, 70, 0.05);

    &:hover {
      background-color: #fafbfb;
    }
  `,
};

const genreVariant = {
  success: css`
    background-color: ${success};
    border-color: ${success};
    &:hover {
      background-color: ${successHover};
    }
  `,
  info: css`
    color: ${info};
    border-color: ${info};
    background-color: white;

    &:hover {
      background-color: ${infoSecHover};
    }
  `,
  cta: css``,
  danger: css``,
  warning: css``,
};

type ComponentProps = {
  width: string;
  set: 'primary' | 'secondary' | 'tertiary';
  genre?: 'cta' | 'info' | 'success' | 'danger' | 'warning';
  size?: 'small' | 'big';
  disabled?: boolean;
  loading: string;
};

const Component = styled.button<ComponentProps>`
  width: ${(props) => props.width};
  height: ${(props) => {
    if (props.size === 'small') return '24px';
    if (props.size === 'big') return '48px';
    return '40px';
  }};
  max-width: 100%;
  padding: ${(props) => {
    if (props.size === 'big') return `0 ${space * 3}px`;
    return `0 ${space * 2}px`;
  }};
  border-radius: ${(props) => {
    if (props.size === 'small') return '6px';
    if (props.size === 'big') return '14px';
    return '10px';
  }};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  font-weight: ${(props) => {
    if (props.set === 'primary') return '600';
    return '500';
  }};
  transition: background-color 200ms ease;
  border: 1px solid ${ctaOutline};
  cursor: pointer;

  &:hover {
    background-color: ${ctaHover};
  }

  ${({ set }) => setVariant[set || 'primary']};
  ${({ genre }) => (genre && genreVariant[genre]) || 'cta'};

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${lineLight};
      border-color: ${lineLight};
      color: ${fontDisabled};
      pointer-events: none;
      cursor: default;
    `};

  ${({ loading }) =>
    loading &&
    css`
      background-color: ${lineLight};
      border-color: ${lineLight};
      color: ${fontDisabled};
      pointer-events: none;
      cursor: default;
    `};
`;

type IconProps = {
  position: 'left' | 'right';
};

const positionVariant = {
  left: css`
    margin-right: ${space * 1.5}px;
  `,
  right: css`
    margin-left: ${space * 1.5}px;
  `,
};

const Icon = styled(FontAwesomeIcon)<IconProps>`
  ${({ position }) => positionVariant[position]};
`;

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  type?: 'button' | 'submit';
  width?: string;
  set?: 'primary' | 'secondary' | 'tertiary';
  genre?: 'cta' | 'info' | 'success' | 'danger' | 'warning';
  size?: 'small' | 'big';
  iconLeft?: IconLookup | false;
  iconRight?: IconLookup;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

const Button = ({
  children,
  type = 'button',
  width = 'auto',
  set = 'primary',
  genre = 'cta',
  size,
  iconLeft,
  iconRight,
  disabled = false,
  loading = false,
  onClick,
  ...rest
}: ButtonProps) => {
  return (
    <Component
      width={width}
      type={type}
      onClick={onClick}
      set={set}
      genre={genre}
      disabled={disabled}
      loading={loading ? 'true' : ''}
      size={size}
      {...rest}
    >
      {iconLeft && <Icon position="left" icon={iconLeft} />}
      {children}
      {iconRight && !loading && <Icon position="right" icon={iconRight} />}
      {loading && <Icon position="right" icon={faSpinnerThird} spin />}
    </Component>
  );
};

export default Button;
