import React from 'react';
import styled from 'styled-components';

import { BookSpread } from '@graphql/generated/graphql';

type Props = {
  spread: BookSpread;
};

const StyledThumbnailWrapper = styled.div`
  position: relative;
`;
const StyledDivider = styled.div`
  position: absolute;
  bottom: 0;
  left: calc(50% - 1px);
  height: 100%;
  width: 2px;
  background-color: #e1e4e8;
`;
const StyledThumbnail = styled.img`
  scale: 1.025;
`;

export default function SpreadThumbnail({ spread }: Props) {
  return (
    <StyledThumbnailWrapper>
      <StyledThumbnail src={spread.thumbnailURL} />
      <StyledDivider />
    </StyledThumbnailWrapper>
  );
}
