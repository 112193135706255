import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  ApolloLink,
  InternalRefetchQueriesInclude,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';
import { IncomingMessage } from 'http';
import Cookies from 'js-cookie';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';
import { useMemo } from 'react';

import { GetBookByIdDocument } from './generated/graphql';

export type ApolloClientContext = {
  req?: IncomingMessage & {
    cookies: NextApiRequestCookies;
  };
};

const isBrowser = typeof window !== 'undefined';
let apolloClient: ApolloClient<NormalizedCacheObject>;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const linkFromURIs = createLink({
  uris: {
    http: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT as string,
    ws: process.env.NEXT_PUBLIC_WEBSOCKETS_ENDPOINT as string,
  },
});
const link = ApolloLink.from([errorLink, linkFromURIs]);

const createApolloClient = () => {
  return new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
    cache: new InMemoryCache(),
    link: createAuthLink().concat(link),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
      },
    },
  });
};

export const getApolloClient = (ctx?: ApolloClientContext) => {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (ctx) {
    if (ctx.req) {
      let { req } = ctx;
      // console.log('req', req);
      // Do something with the cookies here, maybe add a header for authentication
      req.cookies;
    }

    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.cache.extract();

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore(existingCache || {});
  }

  // For SSG and SSR always create a new Apollo Client
  if (!isBrowser) return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
};

export function useApollo(initialState: undefined) {
  const store = useMemo(() => getApolloClient(initialState), [initialState]);
  return store;
}

export const refetchQueries = (
  bookId?: string,
): InternalRefetchQueriesInclude => [
  {
    query: GetBookByIdDocument,
    variables: { bookId },
  },
];

function createLink(cfg: { uris: { http: string; ws: string } }) {
  if (!isBrowser) {
    return createHttpLink({ uri: cfg.uris.http });
  }

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  //
  // @see https://www.apollographql.com/docs/react/data/subscriptions/#3-split-communication-by-operation-recommended
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    createWslink({ uri: cfg.uris.ws }),
    createHttpLink({ uri: cfg.uris.http }),
  );
}

function createHttpLink(cfg: { uri: string }) {
  return createUploadLink({
    uri: cfg.uri,
    fetch,
  }) as unknown as ApolloLink;
}

function createWslink(cfg: { uri: string }) {
  return new GraphQLWsLink(
    createClient({
      url: cfg.uri,
      // Makes sure to retry on connection failure
      shouldRetry: () => true,
      // Establish a connection on first subscribe and close on last unsubscribe. Use the subscription sink’s error to handle errors.
      lazy: true,
      // Increased from default 5 to 15 to give it some more attempts
      retryAttempts: 15,
      connectionParams: () => {
        const token = getAccessToken();
        if (!token) {
          return {};
        }

        return {
          Authorization: `Bearer ${token}`,
        };
      },
    }),
  );
}

function createAuthLink() {
  return setContext((_, { headers, cache }) => {
    const opts = { headers: { ...headers } };

    const token = getAccessToken();
    if (token) {
      opts.headers.Authorization = `Bearer ${token}`;
    }

    return opts;
  });
}

function getAccessToken() {
  return Cookies.get('accessToken');
}
