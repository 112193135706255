import { ApolloClient } from '@apollo/client';
import { create } from 'zustand';

import { BookSpread, GetSpreadByIdDocument } from '@graphql/generated/graphql';

import { SpreadSide } from '../types/spread-side';

// Define the spreadStore type
type SpreadStore = {
  activeSpread: BookSpread | null;
  activeSpreadSide?: keyof typeof SpreadSide | null;
  setActiveSpread: (spread: BookSpread) => void;
  fetch: (spreadId: string, client: ApolloClient<any>) => Promise<void>;
  setActiveSpreadSide: (side: keyof typeof SpreadSide | null) => void;
};

// SPREAD_STORE_DEBUG set to true will log all actions in the console
const SPREAD_STORE_DEBUG = false;

export const useSpreadStore = create<SpreadStore>()((set) => {
  // Return the store with implemented methods
  return {
    // Set the initial state
    activeSpread: null,
    // Set the active spread in the state
    setActiveSpread: (spread: BookSpread) =>
      set((s) => {
        SPREAD_STORE_DEBUG &&
          console.log('[SpreadStore] setActiveSpread called ', spread);
        return { activeSpread: spread };
      }),
    // Set the active spread side in the state
    setActiveSpreadSide: (side: 'left' | 'right' | null) =>
      set((s) => {
        SPREAD_STORE_DEBUG &&
          console.log('[SpreadStore] setActiveSpreadSide called ', side);
        return { activeSpreadSide: side };
      }),
    // Fetch the active spread and store it in the state
    fetch: async (spreadId: string, client: ApolloClient<any>) => {
      if (!spreadId) return;
      // Fetch spread from API
      SPREAD_STORE_DEBUG &&
        console.log('[SpreadStore] fetch called ', spreadId);
      try {
        const response = await client.query({
          query: GetSpreadByIdDocument,
          variables: { spreadId },
        });
        SPREAD_STORE_DEBUG &&
          console.log('[SpreadStore] fetch resolved ', response.data.spread);
        set((s) => ({
          activeSpread: response.data.spread as BookSpread,
        }));
      } catch (error) {
        console.error('[SpreadStore] fetch error', error);
      }
    },
  };
});
