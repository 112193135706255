import styled from 'styled-components';

import { fontMedium } from '@constants/colors';

const Usp = styled.li`
  color: ${fontMedium};
  list-style-image: url('/images/check.svg');
  padding-left: 12px;
  position: relative;
  transform: translateX(20px);
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
`;

type Props = {
  items: String[];
  [x: string]: any;
};

const List = ({ items, ...rest }: Props) => (
  <ul {...rest}>
    {items.map((item, i) => (
      <Usp key={i}>{item}</Usp>
    ))}
  </ul>
);

export default List;
