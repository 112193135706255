import {
  GetCustomImagesDocument,
  GqlBookElement,
  useContainerAddMutation,
  useFrameAddMutation,
  useStickerAddMutation,
  useTextAddMutation,
} from '@graphql/generated/graphql';

type Props = {
  bookId: string;
  spreadId: string;
  element: GqlBookElement;
};

export const useCreateElement = () => {
  const [stickerAdd] = useStickerAddMutation();
  const [frameAdd] = useFrameAddMutation();
  const [containerAdd] = useContainerAddMutation();
  const [textAdd] = useTextAddMutation();

  const createElement = ({ bookId, spreadId, element }: Props) => {
    switch (element?.__typename) {
      case 'BookElementContainer':
        containerAdd({
          variables: {
            spreadId,
            width: element.width,
            height: element.height,
            top: element.top,
            left: element.left,
            rotation: element.rotation,
            round: element.round,
            imageId: element.image.blobID === '' ? null : element.image.blobID,
            imageTop: element.imageTop,
            imageLeft: element.imageLeft,
            imageWidth: element.imageWidth,
            imageHeight: element.imageHeight,
          },
          refetchQueries: !!element.image.blobID
            ? [
                {
                  query: GetCustomImagesDocument,
                  variables: { bookId },
                },
              ]
            : [],
        });
        break;
      case 'BookElementSticker':
        stickerAdd({
          variables: {
            spreadId,
            width: element.width,
            height: element.height,
            top: element.top,
            left: element.left,
            rotation: element.rotation,
            imageId: element.image.blobID === '' ? null : element.image.blobID,
          },
        });
        break;
      case 'BookElementFrame':
        frameAdd({
          variables: {
            spreadId,
            width: element.width,
            height: element.height,
            top: element.top,
            left: element.left,
            rotation: element.rotation,
            frameId: element.frame.blobID,
            imageId: element.image.blobID === '' ? null : element.image.blobID,
            imageTop: element.imageTop,
            imageLeft: element.imageLeft,
            imageWidth: element.imageWidth,
            imageHeight: element.imageHeight,
          },
          refetchQueries: !!element.image.blobID
            ? [
                {
                  query: GetCustomImagesDocument,
                  variables: { bookId },
                },
              ]
            : [],
        });
        break;
      case 'BookElementText':
        textAdd({
          variables: {
            spreadId,
            width: element.width,
            height: element.height,
            top: element.top,
            left: element.left,
            rotation: element.rotation,
            content: element.content,
            placeholder: element.placeholder,
            fontFamily: element.fontFamily,
            fontSize: element.fontSize,
            fontWeight: element.fontWeight,
            fontStyle: element.fontStyle,
            textDecoration: element.textDecoration,
            textAlign: element.textAlign,
            color: element.color,
          },
        });
        break;
    }
  };

  return { createElement };
};
