import { create } from 'zustand';

// Define the type for the toggle state
type ToggleState = {
  toggles: Record<string, boolean>;
  setToggle: (bookId: string, name: string, value: boolean) => void;
  getToggle: (bookId: string, name: string) => boolean;
  initializeToggle: (
    bookId: string,
    name: string,
    defaultValue: boolean,
  ) => void;
};

const useToggleStore = create<ToggleState>((set, get) => ({
  toggles: {}, // Initial empty state for toggles

  // Set a toggle and save it to localStorage
  setToggle: (bookId, name, value) => {
    const toggleName = bookId ? `${bookId}_${name}` : name;
    localStorage.setItem(toggleName, JSON.stringify(value));
    set((state) => ({
      toggles: { ...state.toggles, [toggleName]: value },
    }));
  },

  // Get the state of a toggle
  getToggle: (bookId, name) => {
    const toggleName = bookId ? `${bookId}_${name}` : name;
    const storedValue = localStorage.getItem(toggleName);
    return storedValue !== null
      ? JSON.parse(storedValue)
      : get().toggles[toggleName] ?? false;
  },

  // Initialize a toggle with a default value
  initializeToggle: (bookId, name, defaultValue) => {
    const toggleName = bookId ? `${bookId}_${name}` : name;
    const storedValue = localStorage.getItem(toggleName);
    const value = storedValue !== null ? JSON.parse(storedValue) : defaultValue;
    set((state) => ({
      toggles: { ...state.toggles, [toggleName]: value },
    }));
  },
}));

export default useToggleStore;
