import { laptop, upToLaptop, upToTablet } from '@constants/devices';

import useMediaMatches from './useMediaMatches';

const useMediaQuery = () => {
  const isMobile = useMediaMatches(`(${upToTablet})`);
  const isTablet = useMediaMatches(`(${upToLaptop})`);
  const isLaptop = useMediaMatches(`(${laptop})`);
  const isPortrait = useMediaMatches('(orientation: portrait)');

  return { isMobile, isTablet, isLaptop, isPortrait };
};

export default useMediaQuery;
