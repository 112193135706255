export const gaTrackingId = process.env.NEXT_PUBLIC_GA_TRACKING_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (!gaTrackingId) return;
  window.gtag('config', gaTrackingId, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (
  action: string,
  category: string,
  label: string,
  value: string,
) => {
  if (!gaTrackingId) return;
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
};
