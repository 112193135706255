import breakpoints from './breakpoints';

export const upToSmallTablet = `max-width: ${breakpoints.upToSmallTablet}px`; // 543;  Small devices (landscape phones)
export const smallTablet = `min-width: ${breakpoints.smallTablet}px`; // 544;  Small devices (landscape phones)
export const upToTablet = `max-width: ${breakpoints.upToTablet}px`; // 767;  Medium devices (tablets)
export const tablet = `min-width: ${breakpoints.tablet}px`; // 768;  Medium devices (tablets)
export const upToLaptop = `max-width: ${breakpoints.upToLaptop}px`; // 991;  Large devices (desktops)
export const laptop = `min-width: ${breakpoints.laptop}px`; // 992;  Large devices (desktops)
export const upToDesktop = `max-width: ${breakpoints.upToDesktop}px`; // 1199;  Extra large devices (large desktops)
export const desktop = `min-width: ${breakpoints.desktop}px`; // 1200;  Extra large devices (large desktops)

const device = {
  upToSmallTablet,
  smallTablet,
  upToTablet,
  tablet,
  upToLaptop,
  laptop,
  upToDesktop,
  desktop,
};

export default device;
