import { createGlobalStyle } from 'styled-components';

const spaceAmounts = [0, 8, 16, 24, 32, 40, 48]; // Adjust this to include the pixel amounts you need.
const sides = ['top', 'right', 'bottom', 'left'];

export default createGlobalStyle`
  ${spaceAmounts
    .map(
      (size) => `

    ${sides
      .map(
        (side) => `
      .m${side[0]}-${size}{
        margin-${side}: ${size}px !important;
      }
    `,
      )
      .join('')}

    .m-${size}{
      margin: ${size}px !important;
    }
  `,
    )
    .join('')}

  .m-0 {
    margin: 0;
  }
`;
