import { faLockAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

import zindex from '@utils/zindex';

const Component = styled.div`
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.12);
  z-index: ${zindex('locked-page')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.div<{ size: number }>`
  background: white;
  border-radius: 50%;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 1px 0px rgba(10, 22, 70, 0.06),
    0px 6px 6px -1px rgba(10, 22, 70, 0.1);

  svg {
    width: 50% !important;
    height: auto;
  }
`;

type Props = {
  size?: number;
};

const SpreadLockOverlay = ({ size = 24 }: Props) => (
  <Component>
    <Icon size={size}>
      <FontAwesomeIcon icon={faLockAlt} />
    </Icon>
  </Component>
);

export default SpreadLockOverlay;
