import { createGlobalStyle } from 'styled-components';

import { lineLight } from '../constants/colors';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
  }

  html {
    height: 100%;
    width: 100%;
  }

  body {
    height: 100%;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    scroll-behavior: smooth;

    #__next {
      height: 100%;
      width: 100%;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
    /* text-indent: -9999px */
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button, input, textarea {
    font-family: inherit;
  }

  hr {
    height: 1px;
    background: ${lineLight};
  }
`;
