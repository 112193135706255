import React from 'react';

/* Styles */
import Core from './core';
import Fonts from './fonts';
import Headers from './headers';
import Spacing from './spacing';

export default function GlobalStyle() {
  return (
    <>
      <Core />
      <Fonts />
      <Headers />
      <Spacing />
    </>
  );
}
