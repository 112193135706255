import * as Sentry from '@sentry/nextjs';

import packageJson from './package.json';

const SENTRY_DSN: string = process.env.NEXT_PUBLIC_SENTRY_DSN || '';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://4e177b1e5af44f3691abcf895142bd49@o909283.ingest.sentry.io/6106243',
    tracesSampleRate: 0.2,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'unknown',
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    release: `belmondofoto@${packageJson.version}`,
  });
}
