import { ApolloProvider } from '@apollo/client';
import '@fortawesome/fontawesome-svg-core/styles.css';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';

import CookieBar from '@components/CookieBar';
import { BookProvider } from '@context/BookContext';
import { DialogProvider } from '@context/DialogContext';
import CrispChat from '@elements/CrispChat';
import Dialog from '@elements/Dialog';
import { useApollo } from '@graphql/Apollo';
import { gaTrackingId, pageview } from '@utils/gtag';
import { gtmTrackingId } from '@utils/tag-manager';

import GlobalStyle from '../styles';

function MyApp({
  Component,
  pageProps,
}: AppProps<{ initialApolloState: undefined }>) {
  const router = useRouter();
  const apolloClient = useApollo(pageProps.initialApolloState);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      hotjar.initialize(2964990, 1);
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => pageview(url);
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [router.events]);

  return (
    <>
      {gtmTrackingId && (
        <Script id="google-tag-manager-script" strategy="afterInteractive">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtmTrackingId}');`}
        </Script>
      )}
      {gaTrackingId && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
          />
          <Script id="GA4-script">
            {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gaTrackingId}', {
              page_path: window.location.pathname,
            });`}
          </Script>
        </>
      )}
      <ApolloProvider client={apolloClient}>
        <BookProvider>
          <DialogProvider>
            <GlobalStyle />
            <Component {...pageProps} />
            <Dialog />
            <CrispChat />
            <CookieBar />
          </DialogProvider>
        </BookProvider>
      </ApolloProvider>
    </>
  );
}

export default MyApp;
