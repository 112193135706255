import { Crisp } from 'crisp-sdk-web';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { isBrowser } from '@constants/live-prep';
import { useGetAccountInfoQuery } from '@graphql/generated/graphql';

const CrispChat = () => {
  const router = useRouter();
  const { data } = useGetAccountInfoQuery();

  useEffect(() => {
    if (isBrowser) {
      Crisp.configure('5bd4b986-68a1-42ef-bf83-c876df935aef');

      // Hide Crisp chat widget by default
      window.$crisp = window.$crisp || [];
      window.$crisp.push(['do', 'chat:hide']);

      // Set user info if available
      if (data?.account && data?.account.email) {
        const { firstName, lastName, email } = data.account;
        Crisp.user.setEmail(email);
        Crisp.user.setNickname(`${firstName} ${lastName}`);
      }

      window.$crisp.push([
        'on',
        'chat:closed',
        () => {
          window.$crisp.push(['do', 'chat:hide']);
        },
      ]);
    }
  }, [router.pathname, data]);

  return null;
};

export default CrispChat;
