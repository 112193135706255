import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import useToggleStore from 'store/toggleStore';
import styled from 'styled-components';

import { fontLight, success } from '@constants/colors';
import { space } from '@constants/spaces';

const Component = styled.label`
  display: flex;
  align-items: center;
`;

const Labels = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${space * 3}px;
  overflow: hidden;
  user-select: none;

  h5 {
    font-weight: 400;
  }

  span {
    line-height: 24px;
    color: ${fontLight};
  }
`;

const Holder = styled.div`
  width: 32px;
  height: 20px;
  flex-shrink: 0;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin-left: auto;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

const Input = styled.div<{ disabled: boolean; checked: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ checked }) => (checked ? success : '#c9ced6')};
  transition: background-color 300ms;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};

  &:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 0px 1px 0px rgba(10, 22, 70, 0.06),
      0px 6px 6px -1px rgba(10, 22, 70, 0.1);
    transform: ${({ checked }) => checked && 'translateX(12px)'};
    transition: transform 300ms;
  }
`;

type Toggleprops = {
  name: string;
  bookId?: string;
  label?: string;
  subLabel?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
};

const Toggle = ({
  name,
  bookId = '',
  label = '',
  subLabel = '',
  defaultChecked = false,
  disabled = false,
  onChange,
}: Toggleprops) => {
  const { register } = useFormContext();
  const { getToggle, setToggle, initializeToggle } = useToggleStore();
  const checked = getToggle(bookId, name);

  useEffect(() => {
    initializeToggle(bookId, name, defaultChecked);
  }, [bookId, name, defaultChecked, initializeToggle]);

  const change = () => {
    const newChecked = !checked;
    setToggle(bookId, name, newChecked);
    onChange(newChecked);
  };

  return (
    <Component htmlFor={name}>
      {label && (
        <Labels>
          <h5>{label}</h5>
          <span>{subLabel}</span>
        </Labels>
      )}
      <Holder>
        <input
          type="checkbox"
          id={name}
          {...register(name)}
          checked={checked}
          onChange={change}
          disabled={disabled}
        />
        <Input checked={checked} disabled={disabled} />
      </Holder>
    </Component>
  );
};

export default Toggle;
